import { SauceGallery as SauceGalleryComponent } from '@components/common/Sauce'
import { Facebook, Instagram, Pinterest, Youtube } from '@components/icons'
import { Container } from '@components/ui'
import { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'
interface Props {
  slice: any
}
const SauceGallery = ({ slice }: Props) => {
  const { locale } = useRouter()
  const primary = slice?.primary
  const title = primary?.title
  const titleBackgroundColour = primary?.title_background_colour
  const galleryHandle = primary?.sauceGalleryId
  const topPadding = primary?.top_padding || 0
  const bottomPadding = primary?.bottom_padding || 0
  const backgroundColour = primary?.background_colour || '#FFFFFF'
  const displaySocialLinks = primary?.display_social_media_links
  const socialMediaSectionTitle = primary?.social_media_section_title
  const sauceId = getConfigByLocale(locale, 'sauceId')
  const socialData = {
    facebook: {
      link: 'https://www.facebook.com/BauwerkColour/',
    },
    instagram: {
      link: 'https://www.instagram.com/bauwerkcolour/',
    },
    youtube: {
      link: 'https://www.youtube.com/channel/UCMMPRCMM6M1kDh614-iiB5g',
    },
    pinterest: {
      link: 'https://www.pinterest.com.au/bauwerkcolour/',
    },
  }
  const { youtube, instagram, facebook, pinterest } = socialData
  if (!galleryHandle || !sauceId) return <></>
  return (
    <div className="sauceGallery">
      <div className="sauceGalleryTitle ">
        {title && (
          <Container>
            <div className="heading-mobile-3 md:heading-3 mx-auto w-full pb-8 text-center  text-brand-dark-grey md:w-160 md:pb-16">
              {title}
            </div>
          </Container>
        )}
      </div>
      <section key={`${galleryHandle}-${locale}`}>
        <SauceGalleryComponent galleryHandle={galleryHandle} />
      </section>
      {displaySocialLinks && (
        <Container>
          <div className="flex items-center gap-4 md:gap-8">
            <div className="heading-mobile-4 lg:heading-4 text-center font-roboto font-light text-brand-dark-grey">
              {socialMediaSectionTitle}
            </div>
            <div className="flex gap-4">
              {youtube?.link && (
                <a
                  href={youtube?.link || '/'}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <Youtube width={24} height={24} fill={'#646464'} />
                </a>
              )}

              {instagram?.link && (
                <a
                  href={instagram?.link || '/'}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <Instagram width={24} height={24} fill={'#646464'} />
                </a>
              )}

              {facebook?.link && (
                <a
                  href={facebook?.link || '/'}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <Facebook width={24} height={24} fill={'#646464'} />
                </a>
              )}

              {pinterest?.link && (
                <a
                  href={pinterest?.link || '/'}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:opacity-75"
                >
                  <Pinterest width={24} height={24} fill={'#646464'} />
                </a>
              )}
            </div>
          </div>
        </Container>
      )}
      <style jsx>
        {`
          .sauceGalleryTitle {
            background-color: ${titleBackgroundColour};
          }
          .sauceGallery {
            padding-top: ${topPadding};
            padding-bottom: ${bottomPadding};
            background-color: ${backgroundColour};
          }
          .snptwdgt__headr {
            padding-bottom: 32px !important;
          }
        `}
      </style>
      <style jsx global>
        {`
          .snptwdgt__headr {
            padding-bottom: 32px !important;
          }
        `}
      </style>
    </div>
  )
}

export default SauceGallery
