import { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

interface Props {
  galleryHandle: string
  handle?: string
}

const Gallery = ({ galleryHandle, handle }: Props) => {
  const { locale } = useRouter()
  const containerRef = useRef<any>(null)
  const sauceId = getConfigByLocale(locale!, 'sauceId')
  useEffect(() => {
    if (!containerRef || !containerRef.current || !galleryHandle || !sauceId)
      return
    const container = containerRef.current
    if (container) {
      const scriptTag = document.createElement('script')
      scriptTag.src = `//snapppt.com/widgets/widget_loader/${sauceId}/${galleryHandle}.js`
      //https://snapppt.com/widgets/widget_loader/93a66102-0e0b-4403-88ba-15f4890bda8f/bauwerk-colour.js
      // scriptTag.class = 'snapppt-widget'
      scriptTag.async = true
      scriptTag.setAttribute('class', 'snapppt-widget')
      if (handle) {
        scriptTag.setAttribute('data-cursor_handle', handle)
      }

      if (locale !== 'en-au')
        scriptTag.setAttribute(
          'data-custom-visit-url',
          `/${locale}/product/PRODUCT_HANDLE`
        )
      container.appendChild(scriptTag)
    }
  }, [containerRef, galleryHandle, sauceId, handle, locale])

  return <div className="snpt-wdgt--ppg" ref={containerRef}></div>
}

export default Gallery
